export const isDevelopment = () => process.env.NODE_ENV === 'development';

export const ENVIRONMENT = {
  DOMAIN_NAME: 'graphql-server.com',
  GRAPH_URL: isDevelopment() ? 'http://localhost:9004/graphql' : 'https://pubsub.graphql-server.com/graphql', // 'https://1ll4m4sms3.execute-api.eu-west-2.amazonaws.com/prod/graphql'
  GRAPH_WS_URL: isDevelopment() ? 'ws://localhost:9000/subscriptions' : 'wss://pubsub.graphql-server.com/subscriptions',
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
  GOOGLE_AUTH_DOMAIN: process.env.GOOGLE_AUTH_DOMAIN,
  GOOGLE_DATABASE_URL: process.env.GOOGLE_DATABASE_URL,
  GOOGLE_PROJECT_ID: process.env.GOOGLE_PROJECT_ID,
  GOOGLE_STORAGE_BUCKET: process.env.GOOGLE_STORAGE_BUCKET,
  GOOGLE_MESSAGE_SENDER_ID: process.env.GOOGLE_MESSAGE_SENDER_ID,
  GOOGLE_APP_ID: process.env.GOOGLE_APP_ID,
  GOOGLE_MEASURMENT_ID: process.env.GOOGLE_MEASURMENT_ID,
  SENTRY_DSN: process.env.SENTRY_DSN ?? 'https://2f2772c093124e3ea9ce2b1777c86e7f@sentry.io/1774993',
};
