import { BaseGraphqlService } from '@core/services/base';
import { Injectable, Injector } from '@rxdi/core';
import { ApolloClient, importQuery } from '@rxdi/graphql-client';
import { from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IFeatureFlag, IQuery, IUserType } from '~/@introspection';

@Injectable()
export class FeatureFlagsService extends BaseGraphqlService {
  @Injector(ApolloClient)
  public graphql: ApolloClient;

  #cachedFeatureFlags: IFeatureFlag[] = [];

  updateFeatureFlag(id: string, payload: IFeatureFlag) {
    delete payload.__typename;
    delete payload.settings.__typename;
    delete payload.id;
    return this.mutate({
      mutation: 'update-feature-flag.graphql',
      variables: {
        id,
        payload,
      },
    });
  }

  getFeatureFlag(id: string) {
    return this.query({
      query: 'get-feature-flag.graphql',
      variables: { id },
    }).pipe(map(({ data }) => data.getFeatureFlag));
  }

  fetchFeatureFlags() {
    return from(
      this.graphql.query<IQuery>({ query: importQuery('list-feature-flags.graphql'), errorPolicy: 'ignore' })
    ).pipe(
      map(({ data }) => data.getFeatureFlags),
      map((featureFlags) => {
        if (featureFlags?.length) {
          window._excluded_components = featureFlags?.filter((v) => !v.settings.enabled).map((v) => v.name);
          this.#cachedFeatureFlags = featureFlags;
        } else {
          /* Default to build ones */
          // window._excluded_components = [];
          console.error('Unable to fetch feature flags defaulting to build in ones: ');
        }
        return featureFlags;
      }),
      catchError((e) => {
        console.error('Unable to fetch feature flags defaulting to build in ones: ', e);
        return of([] as IFeatureFlag[]);
      })
    );
  }

  isOn(name: string): Observable<boolean> {
    return this.fetchFeatureFlags().pipe(
      map((featureFlags) => {
        const currentFlag = featureFlags.find((flag) => flag.name === name);
        return !!currentFlag?.settings?.enabled;
      })
    );
  }

  isOnSync(name: string) {
    const currentFlag = this.#cachedFeatureFlags.find((userFlag) => userFlag.name === name);
    return !!currentFlag?.settings?.enabled;
  }
}
