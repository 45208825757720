import { DocumentsCompressed } from '@introspection/documents.compressed';
import { LZWService } from '@rxdi/compressor';
import { Module } from '@rxdi/core';
import { convertToPossibleTypes, InMemoryCache } from '@rxdi/graphql-client';
import { GraphqlModule, GraphqlModuleConfig, GraphQLRequest } from '@rxdi/graphql-client';
import { html } from '@rxdi/lit-html';
import { GraphModule } from '@rxdi/ui-kit/graph';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import * as firebase from 'firebase/app';
import localForage from 'localforage';

import { introspectionQueryResultData } from '~/@introspection/fragmentTypes';
import { ENVIRONMENT } from '~/environment';

localForage.config({
  driver: localStorage.getItem('offline-persistance-driver') ?? localForage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
  name: 'graphql-server',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
  description: 'some description',
});

const cache = new InMemoryCache({
  possibleTypes: convertToPossibleTypes(introspectionQueryResultData),
});

if (Boolean(Number(localStorage.getItem('offline-persistance')))) {
  const newPersistor = new CachePersistor({
    cache,
    storage: new LocalForageWrapper(localForage),
    debug: true,
    // persistenceMapper: async (data: any) => {
    //   console.log(JSON.parse(data));
    //   // filter your cached data and queries
    //   return data;
    // },
  });

  newPersistor.restore();
}

const graphConfig: GraphqlModuleConfig = {
  async onRequest(this: GraphQLRequest) {
    const headers = new Headers();
    const user = firebase.auth().currentUser;
    if (user) {
      headers.append('authorization', await user.getIdToken());
    }
    return headers;
  },
  cache,
  //  apolloClientOptions: {
  //   queryDeduplication: false,
  //  },
  cancelPendingRequests: true,
  refreshOnUnauthenticated: true,
  uri: ENVIRONMENT.GRAPH_URL,
  pubsub: ENVIRONMENT.GRAPH_WS_URL,
};

const defaultTemplates = {
  error: (e) => {
    return html` <p style="color: inherit; text-align: center;">${e}</p> `;
  },
  loading: () => {
    return html`
      <div style="text-align: center;">
        <rx-loading palette="danger"></rx-loading>
      </div>
    `;
  },
};

@Module({
  imports: [
    GraphqlModule.forRoot(graphConfig, LZWService.decompress(DocumentsCompressed)),
    GraphModule.forRoot(graphConfig, defaultTemplates),
  ],
})
export class ApolloModule {}
