import { MonoTypeOperatorFunction } from 'rxjs';
import { delay, retryWhen, scan, tap } from 'rxjs/operators';

export function retryWithDelay<T>(seconds: number, count = 1): MonoTypeOperatorFunction<T> {
  return (input) =>
    input.pipe(
      retryWhen((errors) =>
        errors.pipe(
          scan((acc, error) => ({ count: acc.count + 1, error }), {
            count: 0,
            error: null,
          }),
          tap((current) => {
            if (current?.error?.graphQLErrors && current?.error?.message !== 'Failed to fetch') {
              throw current.error;
            }
            if (current?.count > count) {
              throw current.error;
            }
          }),
          delay(seconds * 1000)
        )
      )
    );
}
