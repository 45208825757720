import { WatchQueryOptions } from '@apollo/client/core';
import { Without } from '@core/helpers/types';
import { retryWithDelay } from '@core/operators';
import { Injector } from '@rxdi/core';
import {
  ApolloClient,
  DataProxy,
  importQuery,
  MutationOptions,
  QueryOptions,
  SubscriptionOptions,
} from '@rxdi/graphql-client';
import { GraphQLError } from 'graphql';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { IMutation, IQuery } from '~/@introspection';
import { DocumentTypes } from '~/@introspection/documentTypes';
import { ISubscription } from '~/@introspection/subscriptions';

/**
 * Will retry the request if fails 40 times with 5 seconds delay between the requests
 * That means 200 seconds or 3 minutes and 20 seconds
 */
const retryOnError = <T>() => retryWithDelay<T>(5, 40);

export class BaseGraphqlService {
  @Injector(ApolloClient)
  public graphql: ApolloClient;

  query(
    options: ImportQueryMixin,
    raw?: boolean
  ): Observable<{
    data: IQuery;
  }> {
    return of(options).pipe(
      map((options) => ({ ...options, query: !raw ? importQuery(options.query) : options.query })),
      switchMap((options) => this.graphql.query(options as never)),
      retryOnError()
    );
  }

  watchQuery(options: ImportWatchQueryMixin, raw?: boolean): Observable<{ data: IQuery; errors: GraphQLError[] }> {
    return of(options).pipe(
      map((options) => ({ ...options, query: !raw ? importQuery(options.query) : options.query })),
      switchMap((options) => this.graphql.watchQuery(options) as never),
      retryOnError(),
      tap((e) => {
        if (e.errors) {
          console.error({
            path: e.errors[0]?.path?.join('.'),
            message: e.errors[0]?.message,
          });
        }
      })
    );
  }

  mutate(
    options: ImportMutationMixin,
    raw?: boolean
  ): Observable<{
    data: IMutation;
  }> {
    return of(options).pipe(
      map((options) => ({ ...options, mutation: !raw ? importQuery(options.mutation) : options.mutation })),
      switchMap((options) => this.graphql.mutate(options as never) as never),
      retryOnError()
    );
  }

  subscribe(
    options: ImportSubscriptionMixin,
    raw?: boolean
  ): Observable<{
    data: ISubscription;
  }> {
    return of(options).pipe(
      map((options) => ({ ...options, query: !raw ? importQuery(options.query) : options.query })),
      switchMap((options) => this.graphql.subscribe(options as never) as never),
      retryOnError()
    );
  }
}

interface ImportQueryMixin extends Without<QueryOptions, 'query'> {
  query: DocumentTypes;
}

interface ImportSubscriptionMixin extends Without<SubscriptionOptions, 'query'> {
  query: DocumentTypes;
}

interface ImportMutationMixin extends Without<MutationOptions, 'mutation'> {
  mutation: DocumentTypes;
  update?(proxy: DataProxy, res: { data: IMutation }): void;
}

interface ImportWatchQueryMixin extends WatchQueryOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: DocumentTypes | any;
  update?(proxy: DataProxy, res: { data: IQuery }): void;
}
