import { Bootstrap } from '@rxdi/core';
import { init } from '@sentry/browser';
import { SocialModule, SocialService } from '@stradivario/graphql-server';
import { from } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { FeatureFlagsService } from '~/apps/feature-flags/feature-flags.service';
import { ENVIRONMENT, isDevelopment } from '~/environment';

import { ApolloModule } from './apollo.module';

Bootstrap(
  SocialModule.forRoot({
    apiKey: ENVIRONMENT.GOOGLE_API_KEY,
    authDomain: ENVIRONMENT.GOOGLE_AUTH_DOMAIN,
    databaseURL: ENVIRONMENT.GOOGLE_DATABASE_URL,
    projectId: ENVIRONMENT.GOOGLE_PROJECT_ID,
    storageBucket: ENVIRONMENT.GOOGLE_STORAGE_BUCKET,
    messagingSenderId: ENVIRONMENT.GOOGLE_MESSAGE_SENDER_ID,
    appId: ENVIRONMENT.GOOGLE_APP_ID,
    measurmentId: ENVIRONMENT.GOOGLE_MEASURMENT_ID,
  })
)
  .pipe(
    switchMap(() => Bootstrap(ApolloModule)),
    switchMap((ctx) =>
      ctx
        .get(SocialService)
        .getUser()
        .pipe(
          tap((user) =>
            !isDevelopment()
              ? init({
                  dsn: ENVIRONMENT.SENTRY_DSN,
                  initialScope: {
                    user: { id: user?.uid, email: user?.email },
                  },
                })
              : null
          ),
          switchMap(() => ctx.get(FeatureFlagsService).fetchFeatureFlags()),
          switchMap(() => from(import('./app/app.module')).pipe(switchMap(({ AppModule }) => Bootstrap(AppModule))))
        )
    )
  )

  .subscribe();

if (module['hot']) {
  module['hot'].dispose(() => (document.body.innerHTML = ''));
}
